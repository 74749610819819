import { MUTATION_KEYS, QUERY_KEYS } from '@/app/campaigns/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useUpdateCampaign } from './useUpdateCampaign';

import type { CampaignResource } from '@/app/campaigns/types';

export const useFavoriteCampaign = (campaignId: string) => {
    const updateCampaign = useUpdateCampaign(campaignId);
    const queryClient = useQueryClient();

    // Snapshot the previous value
    const previousCampaign = queryClient.getQueryData<CampaignResource>(
        QUERY_KEYS.campaign(campaignId),
    );

    return useMutation({
        mutationKey: MUTATION_KEYS.favoriteCampaign(campaignId),
        mutationFn: (favourite: boolean) =>
            updateCampaign.mutateAsync({
                ...previousCampaign,
                attributes: {
                    ...previousCampaign.attributes,
                    isFav: favourite,
                },
            }),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['campaigns'],
            });
        },
    });
};
