import { ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from '@/app/campaigns/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { showToast } from '@/app/toasts/utils/showToast';
import { apiPatch, handleRuntimeError } from '@/core/api';

import type { CampaignResource } from '@/app/campaigns/types';

export const useUpdateCampaign = (campaignId: string, successToastMessage?: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: MUTATION_KEYS.updateCampaign(campaignId),
        mutationFn: async (campaign: CampaignResource) =>
            apiPatch(ENDPOINTS.PATCH.updateCampaign(campaignId), { data: campaign }),
        onMutate: async (updatedCampaign) => {
            if (!updatedCampaign || !updatedCampaign.id) {
                return;
            }
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: QUERY_KEYS.campaign(updatedCampaign.id) });

            // Snapshot the previous value
            const previousCampaign = queryClient.getQueryData<CampaignResource>(
                QUERY_KEYS.campaign(updatedCampaign.id),
            );

            // Optimistically update to the new value
            queryClient.setQueryData(QUERY_KEYS.campaign(updatedCampaign.id), updatedCampaign);

            // Return a context object with the snapshotted value
            return { previousCampaign };
        },
        onError: (err, updatedCampaign, context) => {
            // If the mutation fails, use the context returned from onMutate to roll back
            queryClient.setQueryData(
                QUERY_KEYS.campaign(updatedCampaign.id),
                context?.previousCampaign,
            );

            handleRuntimeError(err, {
                debugMessage: `updating campaign ${updatedCampaign.id} failed:`,
            });
        },
        onSuccess: () => {
            if (successToastMessage) {
                showToast({ type: 'success', message: successToastMessage });
            }
        },
        onSettled: (data, error, variables) => {
            // Always refetch after error or success to ensure we have the correct data
            queryClient.invalidateQueries({ queryKey: QUERY_KEYS.campaign(variables.id) });
        },
    });
};
