import { useRouter } from 'next/router';
import { memo } from 'react';

import EmptyState from '@/app/campaigns/components/Overview/EmptyState';
import { Pagination } from '@/app/campaigns/components/Overview/Pagination';
import { useCampaigns } from '@/app/campaigns/hooks/queries/useCampaigns';
import { CampaignFilter, TestId } from '@/app/campaigns/types';
import { Loader } from '@/ui/components/Loader';
import { CapabilitiesTarget, useUserCapabilities } from '@/utils/hooks/useUserCapabilities';

import { Grid } from './Grid';
import { Table } from './Table';
import { useLastDisplayedMode } from '../../hooks/useLastDisplayedMode';

import type { CampaignOrder } from '@/app/campaigns/types';

export interface Props {
    workspaceId: string;
}

const Overview = ({ workspaceId }: Props) => {
    const { query } = useRouter();
    // TODO [Papercut]: Read display value from QS params instead of redux: https://linear.app/perspective/issue/WKS-154/render-funnels-based-on-display-property-from-query-string-params
    const isGrid = useLastDisplayedMode() === 'grid';
    const campaignCapability = useUserCapabilities(CapabilitiesTarget.Campaign);
    const isSearching = query?.search && query?.search?.length > 0;

    const { data, isLoading: fetchingCampaigns } = useCampaigns({
        filter: isSearching
            ? CampaignFilter.search
            : ((query.filter as string) ?? CampaignFilter.all),
        ...(isSearching ? { search: query.search as string } : {}),
        workspaceId: (query?.workspaceId as string) || workspaceId,
        crmCampaignsOnly: !campaignCapability.canUpdate,
        page: query.page as string,
        order: query.order as CampaignOrder,
    });
    const { campaigns } = data || {};

    const Display = isGrid ? Grid : Table;

    return (
        <Loader loading={fetchingCampaigns} data-testid={TestId.Campaigns}>
            {!fetchingCampaigns ? (
                <>
                    {campaigns?.length > 0 ? (
                        <Display campaigns={campaigns} />
                    ) : (
                        <EmptyState workspaceId={workspaceId} />
                    )}
                    <Pagination />
                </>
            ) : null}
        </Loader>
    );
};

export default memo(Overview);
